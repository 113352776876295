<form class="pet-details-container fnol-media-container" [formGroup]="petInfoForm" #petInfoFormElement>
  <div class="title">
		<span class="title-1">
			{{ 'pet-details.title'|translate:{name: petInfoForm.get('petName')?.value || ('pet-details.your-pet' | translate)} }}
		</span>
  </div>

  <div class="fnol-form-field">
    <label>{{ 'pet-details.pet-name' | translate }}</label>
    <input type="text" name="petName" formControlName="petName"/>
  </div>

  <div class="fnol-form-field">
    <label>{{ 'pet-details.diagnoses'|translate }}</label>
    <calingo-fnol-autocomplete></calingo-fnol-autocomplete>
  </div>

  <div class="fnol-form-field" [class.hidden]="!showDiagnosisAdditionalInformation()">
    <label>{{ 'pet-details.diagnosis-additional-info'|translate }}</label>
    <textarea type="text" formControlName="diagnosisAdditionalInformation"></textarea>
  </div>

  <div class="fnol-form-field" [class.hidden]="!showAccidentDateField()">
    <label>{{ 'pet-details.accident-date-field-info'|translate }}</label>

    <div class="form-field-date">
      <input matInput [matDatepicker]="accidentDateField" (click)="accidentDateField.open()"
             [max]="maxDate"
             formControlName="accidentDateField">
      <mat-datepicker-toggle matSuffix [for]="accidentDateField"></mat-datepicker-toggle>
      <mat-datepicker #accidentDateField></mat-datepicker>
    </div>
  </div>

  <div class="fnol-form-field" [class.hidden]="!showFieldsForNotOnlyPreventionsOrAccident() || onlyAccidentDiagnosisSelected()">
    <label>{{ 'pet-details.condition-noted' | translate }}</label>

    <div class="form-field-date">
      <input matInput [matDatepicker]="dateConditionNoticed" (click)="dateConditionNoticed.open()" [max]="maxDate"
             formControlName="dateConditionNoticed">
      <mat-datepicker-toggle matSuffix [for]="dateConditionNoticed"></mat-datepicker-toggle>
      <mat-datepicker #dateConditionNoticed></mat-datepicker>
    </div>
  </div>

  <div class="fnol-form-field" [class.hidden]="!showFieldsForNotOnlyPreventionsOrAccident()">
    <label>{{ 'pet-details.condition-previously-treated'|translate:{name: petInfoForm.get('petName')?.value || ('pet-details.your-pet' | translate)} }}</label>

    <div class="radio">
      <input type="radio" id="treatedYes"
             name="conditionPreviouslyTreated" [value]="true" checked formControlName="conditionPreviouslyTreated">
      <label for="treatedYes">{{ 'pet-details.yes' | translate }}</label>

      <input type="radio" id="treatedNo"
             name="conditionPreviouslyTreated" [value]="false" formControlName="conditionPreviouslyTreated">
      <label for="treatedNo">{{ 'pet-details.no' | translate }}</label>
    </div>
  </div>


  <div class="fnol-form-field" [class.hidden]="!showFieldsForNotOnlyPreventionsOrAccident()" *ngIf="petInfoForm.get('conditionPreviouslyTreated')?.value">
    <label>{{ 'pet-details.condition-first-treated'|translate:{name: petInfoForm.get('petName')?.value || ('pet-details.your-pet' | translate)} }}</label>

    <div class="form-field-date">
      <input matInput [matDatepicker]="dateConditionFirstTreated" (click)="dateConditionFirstTreated.open()"
             [max]="maxDate"
             formControlName="dateConditionFirstTreated">
      <mat-datepicker-toggle matSuffix [for]="dateConditionFirstTreated"></mat-datepicker-toggle>
      <mat-datepicker #dateConditionFirstTreated></mat-datepicker>
    </div>
  </div>

  <div class="fnol-form-field">
    <label>{{ 'pet-details.vet-invoice' | translate }} </label>
    <ul>
      <li> {{ 'pet-details.vet-invoice-format-requirement-header' | translate }}</li>
      <ul>
        <li> {{ 'pet-details.vet-invoice-format-requirement1' | translate }}</li>
        <li> {{ 'pet-details.vet-invoice-format-requirement2' | translate }}</li>
        <li> {{ 'pet-details.vet-invoice-format-requirement3' | translate }}</li>
        <li> {{ 'pet-details.vet-invoice-format-requirement4' | translate }}</li>
      </ul>
      <li> {{ 'pet-details.vet-invoice-info-requirement-header' | translate }}</li>
      <!--
        <ul>
          <li> {{ 'pet-details.vet-invoice-info-requirement1' | translate }}</li>
          <li> {{ 'pet-details.vet-invoice-info-requirement2' | translate }}</li>
          <li> {{ 'pet-details.vet-invoice-info-requirement3' | translate }}</li>
          <li> {{ 'pet-details.vet-invoice-info-requirement4' | translate }}</li>
        </ul>
      -->

    </ul>

    <img [src]="exampleInvoiceImageSrc" alt="">

    <calingo-fnol-drag-n-drop [singleFileAllowed]="true" #vetInvoice
                              (onFileChange)="onFileListChange()"></calingo-fnol-drag-n-drop>
    <mat-error
      *ngIf="vetInvoice.fileList.length > 0 && showFileSizeError">{{ 'pet-details.file-size-error' | translate }}
    </mat-error>
    <mat-error
      *ngIf="vetInvoice.fileList.length > 0 && showFileTypeError">{{ 'pet-details.file-type-error' | translate }}
    </mat-error>
  </div>

  <div class="fnol-form-field">
    <label>{{ 'pet-details.vet-report' | translate }}</label>
    <!--
      <ul>
        <li> {{ 'pet-details.vet-report-info1' | translate }}</li>
        <li> {{ 'pet-details.vet-report-info2' | translate }}</li>
        <li> {{ 'pet-details.vet-report-info3' | translate }}</li>
      </ul>
    -->
    <calingo-fnol-drag-n-drop [singleFileAllowed]="true" #additionalFiles
                              (onFileChange)="onFileListChange()"></calingo-fnol-drag-n-drop>
    <mat-error
      *ngIf="additionalFiles.fileList.length > 0 && showFileSizeError">{{ 'pet-details.file-size-error' | translate }}
    </mat-error>
  </div>

  <div class="fnol-form-field">
    <label>{{ 'pet-details.third-party-fault' | translate }} </label>

    <div class="radio">
      <input type="radio" id="thirdPartyFaultYes"
             name="thirdPartyFault" [value]="true" checked formControlName="thirdPartyFault">
      <label for="thirdPartyFaultYes">{{ 'pet-details.yes' | translate }}</label>

      <input type="radio" id="thirdPartyFaultNo"
             name="thirdPartyFault" [value]="false" formControlName="thirdPartyFault">
      <label for="thirdPartyFaultNo">{{ 'pet-details.no' | translate }}</label>
    </div>
  </div>

  <div class="fnol-form-field">
    <label>{{ 'pet-details.additional-info' | translate }}</label>
    <textarea type="text" formControlName="additionalInformation"></textarea>
  </div>

  <!--	<div class="add-another-pet">
      <label class="title-2">{{'pet-details.add-visit' | translate}}</label>
      <button class="icon-button" (click)="addPetInfo()"><img src="/assets/plus-circus-outline.svg" alt="Add visit">
      </button>
    </div>-->

  <div class="button-wrapper">
    <button type="submit" (click)="next()">{{ 'pet-details.next' | translate }}</button>
  </div>
</form>
